import React from 'react';

type BodyPropsType = {
  id: string;
};

// This component is used offset the NavBar when navigating to an element id
const ScrollTag = ({ id }: BodyPropsType) => {
  return (
    <div className="relative">
      <div id={id} className="absolute invisible md:-top-9 -top-7 h-px" />
    </div>
  );
};

export default ScrollTag;
