import React from 'react';
import Image from '../Image';
import { companyContent } from '../../content/index';

const Cards = () => {
  const { cards } = companyContent;

  return (
    <div
      data-testid="cardsContainer"
      className="space-y-10 md:flex md:flex-row md:justify-between md:space-y-0 lg:space-x-11 xl:px-155px"
    >
      {cards.map(card => (
        <div
          data-testid="card"
          key={card.image.alt}
          className="text-center flex flex-col md:w-1/4"
        >
          <div className="h-8.5 md:h-9 lg:h-10.5">
            <Image
              src={`../../company/cards/${card.image.file}`}
              alt={card.image.alt}
              className="mx-auto h-full object-contain"
            />
          </div>
          <h2 className="flex justify-center mt-4 mb-3 text-sm font-semibold md:text-base md:leading-5 md:h-40px">
            {card.title}
          </h2>
          <p className="font-light text-sm leading-6 tracking-tighter lg:tracking-normal md:text-left md:leading-5 lg:text-base">
            {card.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Cards;
