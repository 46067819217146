import React from 'react';
import List from '../List';
import { companyContent } from '../../content/index';
import SideImageContainer from '../SideImageContainer';

const Farm = () => {
  const { header, description, points, image } = companyContent.farm;

  return (
    <div data-testid="farm-container" className="">
      <SideImageContainer
        className="lg:flex items-stretch md:space-x-9 lg:space-x-11"
        classOverride
        imageContainerClassName="max-h-580px md:w-full md:h-auto h-375px"
        imagePath={`../company/farm/${image.file}`}
        imageAlt={image.alt}
      >
        <div className="md:flex mt-5 mx-3 md:mx-0 md:my-8.5 md:self-center">
          <div className="flex flex-col">
            <div className="md:w-400px space-y-5">
              <div className="text-2xl md:text-32px font-bold md:font-extrabold">
                {header}
              </div>
              <div className="text-base lg:w-10/12">{description}</div>
              <div className="ml-6.5">
                <List
                  itemClassName="text-black"
                  itemContainerClassName="mt-3"
                  items={points}
                />
              </div>
            </div>
          </div>
        </div>
      </SideImageContainer>
    </div>
  );
};

export default Farm;
