import * as React from 'react';
import content from '../../content/company.yaml';
import DocumentHead from '../../components/DocumentHead';
import Hero from '../../components/company/Hero';
import Join from '../../components/company/Join';
import Offices from '../../components/company/Offices';
import Mission from '../../components/company/Mission';
import Cards from '../../components/company/Cards';
import Story from '../../components/company/Story';
import Farm from '../../components/company/Farm';
import PageSection from '../../components/PageSection';
import ScrollTag from '../../components/ScrollTag';

type BodyPropsType = {
  viewRef: (node?: Element | null | undefined) => void;
};

const CompanyPage = ({ viewRef }: BodyPropsType) => {
  return (
    <div data-testid="company-page-container" className="md-900px:pt-9 pt-7">
      <DocumentHead content={content.metadata} />
      <PageSection
        className="mb-11 sm:mb-10 sm:mt-4 lg:mx-68px sm:mx-64px"
        testId="hero"
      >
        <Hero viewRef={viewRef} />
      </PageSection>

      <PageSection
        className="sm:hidden px-5 py-9 mb-9"
        testId="mobile-mission-container"
        hasDefaultBackground
      >
        <Mission />
      </PageSection>

      <PageSection
        className="sm:mb-10 lg:mb-11 px-6.5 md-900px:px-64px"
        testId="cards-container"
      >
        <Cards />
      </PageSection>

      <PageSection
        className="hidden sm:flex px-5 py-9 md:py-10"
        testId="mission-container"
        hasDefaultBackground
      >
        <Mission />
      </PageSection>

      <PageSection
        className="py-9 md:py-10 lg:py-11 mx-3 lg:mx-68px md:mx-64px"
        testId="offices-container"
      >
        <Offices />
      </PageSection>

      <PageSection
        className="mb-9 md:mb-10 lg:mb-11 lg:mr-68px md:mr-64px max:mx-68px"
        testId="farm"
        justifyClass="flex justify-center"
      >
        <Farm />
      </PageSection>

      <PageSection
        className="mb-10 ml-3 md:ml-64px lg:ml-68px max:mx-68px"
        testId="story-container"
      >
        <ScrollTag id="timeline" />
        <Story />
      </PageSection>

      <PageSection testId="join" className="md:mb-12 mb-10">
        <Join />
      </PageSection>
    </div>
  );
};

export default CompanyPage;
