import React from 'react';
import Button from '../Button';
import { companyContent } from '../../content/index';
import SideImageContainer from '../SideImageContainer';
import ContentImageTile from '../ContentImageTile';

type BodyPropsType = {
  viewRef: (node?: Element | null | undefined) => void;
};

const Hero = ({ viewRef }: BodyPropsType) => {
  const { header, cta, subheader, images } = companyContent.hero;
  const imagePath = `../company/hero/${images.top.file}`;
  const imageAlt = `../company/hero/${images.top.alt}`;
  const mobileTitleLines = [
    header.mobile.line1,
    header.mobile.line2,
    header.mobile.line3,
  ];
  return (
    <div data-testid="hero-container">
      <SideImageContainer
        className="hidden sm:flex justify-center sm:flex-col md-900px:flex-row"
        imageContainerClassName="sm:w-full md-900px:w-418px lg:w-562px sm:h-392px md-900px:h-auto"
        data-testid="heroImage"
        imagePath={imagePath}
        imageAlt={imageAlt}
        imageOnLeft={false}
      >
        <div className="md:flex flex-grow md:mr-4 md:items-center sm:mt-9 lg:mt-11 sm:mb-9 md-900px:mb-5 lg:mb-10.5">
          <div className="flex flex-col">
            <div className="text-2xl font-extrabold whitespace-nowrap sm:text-4xl md-900px:leading-48px md-900px:w-500px">
              <div>{header.desktop.line1}</div>
              <div>{header.desktop.line2}</div>
              <div>{header.desktop.line3}</div>
            </div>
            <p className="mt-6 text-base sm:text-lg sm:w-400px md-900px:w-500px tracking-tighter">
              {subheader}
            </p>
            <div
              data-testid="desktop-hero-cta"
              className="mt-7 self-center sm:self-start"
            >
              <Button text={cta.desktop} to="/portfolio" />
            </div>
          </div>
        </div>
      </SideImageContainer>
      <div className="sm:hidden flex flex-col">
        <div ref={viewRef} id="company-hero-container">
          <ContentImageTile
            className="w-full h-497px"
            imageClassName=""
            textClassName="top-136px left-42px px-3 text-2xl font-bold drop-shadow-white"
            text={mobileTitleLines}
            imagePath={imagePath}
            alt={imageAlt}
            noTruncate
          />
        </div>
        <p className="mt-8 mx-3 text-base tracking-tighter">{subheader}</p>
        <div data-testid="mobile-hero-cta" className="mt-7 self-center">
          <Button text={cta.desktop} to="/portfolio" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
