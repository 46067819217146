import React from 'react';
import Button from '../Button';
import { companyContent } from '../../content/index';
import Image from '../Image';

function Offices() {
  const { cards, cta, header } = companyContent.offices;

  return (
    <div data-testid="officesContainer" className="flex flex-col">
      <h1
        data-testid="officesHeader"
        className="mb-6 font-semibold text-base md:mb-9 md:mx-0 md:font-bold md:text-xl lg:text-32px"
      >
        {header}
      </h1>
      <div className="flex flex-col mb-7 md:flex-row md:justify-center space-y-25px md:space-y-0 md:space-x-4 lg:justify-between">
        {cards.map(card => (
          <div data-testid="officeCard" key={card.image.alt} className="">
            <div className="w-full pb-100% md:pb-0 relative md:static h-0 md:h-auto overflow-hidden">
              <Image
                src={`../../company/offices/${card.image.file}`}
                alt={card.image.alt}
                className="w-full rounded-md object-cover md:h-200px md-900px:h-275px md-900px:w-210px lg:h-400px lg:w-auto xl:h-435px 2xl:h-400px"
              />
            </div>
            <h2 className="font-semibold mt-3 text-base md:text-tundora lg:text-lg">
              {card.title}
            </h2>
          </div>
        ))}
      </div>
      <div className="mx-auto">
        <Button
          className="w-11.5"
          text={cta.mobile}
          to="/contact"
          type="transparent"
        />
      </div>
    </div>
  );
}

export default Offices;
