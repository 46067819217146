import React from 'react';
import classNames from 'classnames';
import ContentImageTile from './ContentImageTile';

type BodyPropsType = {
  className?: string;
  classOverride?: boolean;
  imagePath: string;
  imageText?: string;
  imageAlt: string;
  imageContainerClassName?: string;
  imageClassName?: string;
  textClassName?: string;
  children: JSX.Element | null;
  imageOnLeft?: boolean | null;
};

const SideImageContainer = ({
  className,
  classOverride = false,
  imagePath,
  imageText,
  imageAlt,
  imageContainerClassName,
  imageClassName,
  textClassName,
  children,
  imageOnLeft = true,
}: BodyPropsType) => {
  const containerClass = classOverride
    ? className
    : classNames('md:flex items-stretch', className);
  return (
    <div className={containerClass}>
      {!imageOnLeft && children}
      <div
        className={`md:flex items-stretch ${
          imageContainerClassName || 'md:w-full md:h-auto h-375px'
        }`}
      >
        <ContentImageTile
          className="w-full md:h-auto h-full"
          imageClassName={imageClassName}
          textClassName={textClassName}
          text={imageText}
          imagePath={imagePath}
          alt={imageAlt}
        />
      </div>
      {imageOnLeft && children}
    </div>
  );
};

export default SideImageContainer;
