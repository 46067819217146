import React from 'react';

type BodyPropsType = {
  direction: 'left' | 'right';
  className?: string;
};

const ArrowIcon = ({ direction, className }: BodyPropsType) => {
  return (
    <svg
      className={`w-full h-full ${className}`}
      width="25"
      height="16"
      viewBox="0 0 25 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      {
        {
          left: (
            <g>
              <path d="M0.792891 8.70711C0.402367 8.31658 0.402367 7.68342 0.792891 7.29289L7.15685 0.928932C7.54738 0.538408 8.18054 0.538408 8.57107 0.928932C8.96159 1.31946 8.96159 1.95262 8.57107 2.34315L2.91421 8L8.57107 13.6569C8.96159 14.0474 8.96159 14.6805 8.57107 15.0711C8.18054 15.4616 7.54738 15.4616 7.15685 15.0711L0.792891 8.70711ZM25 9H1.5V7H25V9Z" />
              <rect width="25" height="16" fill="none" />
            </g>
          ),
          right: (
            <g>
              <path d="M24.7071 8.70711C25.0976 8.31658 25.0976 7.68342 24.7071 7.29289L18.3431 0.928932C17.9526 0.538408 17.3195 0.538408 16.9289 0.928932C16.5384 1.31946 16.5384 1.95262 16.9289 2.34315L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384 14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616 18.3431 15.0711L24.7071 8.70711ZM0.5 9H24V7H0.5V9Z" />
              <rect width="25" height="16" fill="none" />
            </g>
          ),
        }[direction]
      }
    </svg>
  );
};

export default ArrowIcon;
