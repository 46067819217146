import classNames from 'classnames';
import * as React from 'react';
import Button from './Button';
import Image from './Image';

type BodyPropsType = {
  isAnimated?: boolean;
  text?: string | string[];
  imagePath: string;
  alt: string;
  className?: string;
  imageClassName?: string;
  textClassName?: string;
  imageLoading?: 'lazy' | 'eager';
  logoPath?: string;
  logoAlt?: string;
  logoClass?: string;
  hoverCta?: string;
  noTruncate?: boolean;
};

const ContentImageTile = ({
  isAnimated = false,
  text,
  imagePath,
  alt,
  className,
  imageClassName,
  textClassName = '',
  imageLoading = 'lazy',
  logoPath = '',
  logoAlt = '',
  logoClass = '',
  hoverCta = '',
  noTruncate,
}: BodyPropsType) => {
  const imageStyles = classNames(
    'w-full',
    'h-full',
    { 'object-cover': !imageClassName, 'object-center': !imageClassName },
    {
      transform: isAnimated,
      'duration-200': isAnimated,
      'ease-in-out': isAnimated,
      'scale-1.035 lg-and-pointer-fine:hover:scale-1.1 lg-and-pointer-fine:hover:opacity-90':
        isAnimated,
    },
    imageClassName,
  );
  const textStyles = classNames(
    'absolute pointer-events-none',
    {
      'top-0': !textClassName,
      'left-0': !textClassName,
      'p-3': !textClassName,
      'text-white': !textClassName,
      truncate: !noTruncate,
    },
    textClassName,
  );

  const groupHover = hoverCta.length > 0 ? 'group' : '';

  const fadeInAnimation =
    hoverCta.length > 0
      ? 'transition-opacity overflow-hidden transform duration-300 ease-in lg-and-pointer-fine:group-hover:opacity-100'
      : '';
  const fadeOutAnimation =
    hoverCta.length > 0
      ? 'transition-opacity w-full overflow-hidden transform duration-200 ease-in lg-and-pointer-fine:group-hover:opacity-0 h-0'
      : '';

  const logoStyles = classNames(
    'absolute top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 pointer-events-none',
    {
      'w-full h-full': !logoClass,
    },
    logoClass,
    fadeOutAnimation,
  );

  const renderOverlay = () => {
    if (text === undefined) {
      return null;
    }
    return Array.isArray(text) ? (
      <div className={textStyles}>{longText}</div>
    ) : (
      <div className={textStyles}>{text}</div>
    );
  };

  const hoverStyle = classNames(
    fadeInAnimation,
    'opacity-0 absolute top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4',
  );

  const longText: string[] = [];

  if (Array.isArray(text)) {
    text.forEach(line => {
      longText.push(`${line}\n`);
    });
  }

  return (
    <div
      className={`rounded relative overflow-hidden translate-3d-0 ${
        className || ''
      }`}
    >
      {logoPath.length === 0 && (
        <React.Fragment>
          <Image
            className={imageStyles}
            src={imagePath}
            alt={alt}
            loadingProperty={imageLoading}
          />
        </React.Fragment>
      )}
      {logoPath.length > 0 && (
        <div className={classNames(groupHover, 'w-full h-full')}>
          <Image
            className={imageStyles}
            src={imagePath}
            alt={alt}
            loadingProperty={imageLoading}
          />
          <Image
            className={logoStyles}
            src={logoPath}
            alt={logoAlt}
            loadingProperty={imageLoading}
          />
          {hoverCta.length > 0 && (
            <Button
              className={classNames(hoverStyle, 'border-opacity-0')}
              text={hoverCta}
            />
          )}
        </div>
      )}
      {renderOverlay()}
    </div>
  );
};

export default ContentImageTile;
