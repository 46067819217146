import React, {
  Dispatch,
  SetStateAction,
  UIEventHandler,
  useEffect,
  useState,
} from 'react';
import ArrowIcon from './ArrowIcon';

type BodyPropsType = {
  setOnScrollState: Dispatch<
    SetStateAction<UIEventHandler<HTMLDivElement> | undefined>
  >;
  elementGap: number;
  scrollContainer: HTMLDivElement | null;
  sampleElement: HTMLDivElement | null;
  className?: string;
  scrollContainerClass?: string;
  scrollType?: 'snapToElement' | 'visibleElements';
};

const ScrollArrows = ({
  scrollContainer,
  sampleElement,
  elementGap,
  setOnScrollState,
  className,
  scrollContainerClass,
  scrollType = 'visibleElements',
}: BodyPropsType) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState(true);
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainer) {
        setScrollPosition(scrollContainer.scrollLeft);
      }
    };
    setOnScrollState(() => handleScroll);
  }, [setOnScrollState, scrollContainer]);

  const containerClass = scrollContainerClass || 'justify-center flex';

  const handleScrollButton = (direction: 'right' | 'left') => (event: any) => {
    event.preventDefault();
    if (scrollContainer && sampleElement) {
      const scrollAmountPerCard = sampleElement.offsetWidth + elementGap;

      const scrollByAmount = (amount: number) => {
        const maxScrollPosition =
          scrollContainer.scrollWidth - scrollContainer.offsetWidth;
        let scroll = scrollPosition;

        if (direction === 'left')
          scroll =
            scroll - scrollAmountPerCard <= 0
              ? 0
              : Math.ceil((scroll - amount) / scrollAmountPerCard) *
                scrollAmountPerCard;
        else if (direction === 'right')
          scroll =
            scroll + scrollAmountPerCard >= maxScrollPosition
              ? maxScrollPosition
              : Math.floor((scroll + amount) / scrollAmountPerCard) *
                scrollAmountPerCard;

        // eslint-disable-next-line no-param-reassign
        scrollContainer.scrollLeft = scroll;
        setScrollPosition(scroll);

        if (scroll === maxScrollPosition) {
          setIsRightArrowDisabled(true);
        } else {
          setIsRightArrowDisabled(false);
        }

        if (scroll > 0) {
          setIsLeftArrowDisabled(false);
        } else {
          setIsLeftArrowDisabled(true);
        }
      };

      if (scrollType === 'snapToElement') {
        scrollByAmount(scrollAmountPerCard);
      }
      if (scrollType === 'visibleElements') {
        const numCardsDisplayed =
          Math.floor(scrollContainer.offsetWidth / scrollAmountPerCard) || 1;
        scrollByAmount(numCardsDisplayed * scrollAmountPerCard);
      }
    }
  };

  return (
    <div className={containerClass} data-testid="scrollarrow-container">
      <div className={className}>
        <button
          className="w-25px h-3 float-left"
          onClick={handleScrollButton('left')}
          data-testid="left-arrow-button"
          disabled={isLeftArrowDisabled}
        >
          <ArrowIcon
            direction="left"
            className={
              isLeftArrowDisabled
                ? 'fill-current'
                : 'fill-current pointer-fine:hover:fill-secondary'
            }
          />
        </button>
        <button
          className="w-25px h-3 float-right"
          onClick={handleScrollButton('right')}
          data-testid="right-arrow-button"
          disabled={isRightArrowDisabled}
        >
          <ArrowIcon
            direction="right"
            className={
              isRightArrowDisabled
                ? 'fill-current'
                : 'fill-current pointer-fine:hover:fill-secondary'
            }
          />
        </button>
      </div>
    </div>
  );
};

export default ScrollArrows;
