import classNames from 'classnames';
import React, { useEffect, useState, useRef, UIEventHandler } from 'react';
import Image from '../Image';
import ScrollArrows from '../ScrollArrows';
import { companyContent } from '../../content/index';

const Story = () => {
  const { header, cards } = companyContent.story;
  const [onScroll, setOnScroll] = useState<
    UIEventHandler<HTMLDivElement> | undefined
  >();
  const [elementGap, setElementGap] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tileRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setElementGap(window.innerWidth > 768 ? 70 : 20);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div data-testid="storyContainer">
      <h2
        data-testid="storyHeader"
        className="mb-7 md:mb-9 text-2xl md:text-32px font-bold md:font-extrabold"
      >
        {header}
      </h2>
      <div
        ref={containerRef}
        onScroll={onScroll}
        className={classNames(
          'flex flex-row overflow-x-scroll gap-4 md:gap-8 md:pb-6',
          'scroll-smooth scrollbar-none',
        )}
      >
        {cards.map(card => (
          <div
            ref={tileRef}
            data-testid="storyCard"
            key={card.image.alt}
            className="flex flex-col gap-3"
          >
            <div className="rounded-md w-255px h-190px md:h-155px md:w-210px lg:w-275px lg:h-210px shadow-lg">
              <Image
                src={`../../company/story/${card.image.file}`}
                alt={card.image.alt}
                className="rounded-md object-cover w-full h-full"
              />
            </div>
            <div className="flex flex-row-reverse lg:w-275px gap-2">
              <div className="block">
                <div className="origin-bottom transform rotate-90 my-auto mb-3 font-semibold text-base tracking-wide text-tundora lg:text-lg">
                  {card.year}
                </div>
              </div>
              <p className="flex-1 text-xs tracking-wide font-light leading-20px lg:text-sm">
                {card.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <ScrollArrows
        setOnScrollState={setOnScroll}
        elementGap={elementGap}
        scrollContainer={containerRef.current}
        sampleElement={tileRef.current}
        className="w-full mr-3 my-5 lg:mr-68px md:mr-64px md:my-4 max:mr-0"
        scrollType="visibleElements"
      />
    </div>
  );
};

export default Story;
