import React from 'react';
import { companyContent } from '../../content/index';

const Mission = () => {
  const { header, description } = companyContent.mission;

  return (
    <div data-testid="missionContainer" className="text-center">
      <h1
        data-testid="missionHeader"
        className="mb-5 font-semibold text-base md:font-bold md:text-xl lg:text-32px"
      >
        {header}
      </h1>
      <p
        data-testid="missionDescription"
        className="font-normal text-sm tracking-tighter leading-6 mx-auto md:max-w-screen-sm md:tracking-normal md:text-base lg:text-lg"
      >
        {description}
      </p>
    </div>
  );
};

export default Mission;
